@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap" rel="stylesheet);
body {
	padding: 0px;
	margin: 0px;
	font-family: 'Nanum Gothic', sans-serif;
	/*background: black;*/
	/*color: white;*/
	background: black;
	height: 100%;
}




/*<----- FLEX ----->*/

.flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flexLeft {
	display: flex;
	justify-content: flex-start;
}
.flexRight {
	display: flex;
	justify-content: flex-end;
}
.flexCenterColumn {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.flexHeightCenter {
	display: flex;
	align-items: center;
}
.flexHeightBottom {
	display: flex;
	align-items: flex-end;
}
.flexWidthCenter {
	display: flex;
	justify-content: center;
	/*align-items: center;*/
}




/*<----- INPUTS / BUTTONS ----->*/

.input1 {
	height: 38px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid rgb(220,220,220);
	border-radius: 4px;
	box-sizing: border-box;
}
.input1:focus {
	outline: none;
	border: 1px solid white;
	background: rgb(24,24,24);
}
.button {
	height: 38px;
	width: 100%;
	background: rgb(71,134,233);
	border: 0px;
	color: white;
	border-radius: 4px;
	cursor: pointer;
	/*font-weight: bold;*/
}
.button:focus {
	outline: none;
}
.button:active {
	/*opacity: 0.6;*/
	-webkit-filter: brightness(1.3);
	        filter: brightness(1.3);
}
.button:hover {
	/*opacity: 0.8;*/
	-webkit-filter: brightness(1.1);
	        filter: brightness(1.1);
}
.buttonColor {
	background-image: linear-gradient(rgb(140,50,130),rgb(90,20,100));
	border-bottom: 2px solid rgb(70,20,60);
	border-right: 1px solid rgb(70,20,60);
	border-top: 1px solid rgb(90,40,80);
	border-left: 1px solid rgb(40,50,80);
	box-shadow: 0px 0px 8px rgba(0,0,0,0.9);
}



.boxShadow {
	box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
}



/*<----- NAVBAR ----->*/

.navbarContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 50px;
	/*border-bottom: 1px solid rgb(220,220,220);*/
	background: black;
	/*color: rgb(238,109,40);*/
	color: white;
	z-index: 40;
}
.navbarItem {
	padding-left: 20px;
	padding-right: 20px;
	height: 50px;
	cursor: pointer;
	/*font-weight: bold;*/
}






/*<----- FOOTER ----->*/

.footerContainer {
	background: rgb(24,20,30);
	padding: 60px;
	color: white;
}









/*<----- HOME STUFF ----->*/

.homeBanner {
	/*height: 600px;*/
	background-image: linear-gradient(rgb(238,109,40),rgb(238,109,40));
}
.heroText {
	font-size: 60px;
	font-family: 'Staatliches', sans-serif;
}
.heading {
	color: white;
	font-size: 40px;
}
.subHeading {
	color: white;
	font-size: 26px;
}
.textCenter {
	text-align: center;
}
.fullPageImageContainer {

}
.fullPageImage {
	min-height: 100%;
	min-width: 1024px;

	/* Set up proportionate scaling */
	width: 100%;
	height: auto;

	/* Set up positioning */
	position: fixed;
	top: 0;
	left: 0;
}





.hidden {
	opacity: 0;
}
.riseUpStart {
	margin-top: 170px;
	opacity: 0;
}
.riseUp {
	-webkit-animation: rise 1800ms;
	        animation: rise 1800ms;
}
.fadeIn {
	-webkit-animation: imageFade 2400ms;
	        animation: imageFade 2400ms;
}
@-webkit-keyframes imageFade {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes imageFade {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes rise {
	0% {
		opacity: 0;
		margin-top: 170px;
	}
	10% {
		
	}
	60% {
		margin-top: 0px;
	}
	100% {
		opacity: 1;
	}
}
@keyframes rise {
	0% {
		opacity: 0;
		margin-top: 170px;
	}
	10% {
		
	}
	60% {
		margin-top: 0px;
	}
	100% {
		opacity: 1;
	}
}
.puslingColor {
	background-color: rgb(64,105,24);
	-webkit-animation-name: color;
	        animation-name: color;
	-webkit-animation-duration: 2s;
	        animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}
@-webkit-keyframes color {
	0% {
		background-color: rgb(64,105,24);
	}
	50% {
		background-color: #222;
	}
	100% {
		background-color: rgb(64,105,24);
	}
}
@keyframes color {
	0% {
		background-color: rgb(64,105,24);
	}
	50% {
		background-color: #222;
	}
	100% {
		background-color: rgb(64,105,24);
	}
}





.pageMargin {
	margin: auto;
	/*width: 80%;*/
	width: 900px;
}









@media only screen and (max-width: 900px) {
	.pageMargin {
		width: 90%;
	}
	.heroText {
		font-size: 60px;
	}
	.heading {
		font-size: 26px;
	}
	.subHeading {
		font-size: 20px;
	}
}

















